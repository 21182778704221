
.thanks-app-header {
    position: relative;
    color: #fff;
    min-height: 780px;
    height: auto;
    width: 100%;
    display: block;
}

.thanks-app-header-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        71.68deg,
        #0e63f4 0%,
        #5b31ff 63.47%,
        #5a33ff 78.46%,
        #00e0fa 100%
    );
    overflow: hidden;
}
.thanks-app-header-background::after {
    content: "";
    width: 100%;
    position: absolute;
    background-color: #fff;
    height: 100%;
    bottom: -70%;
    transform: skewY(-15deg);
}

.thanks-app-header-retangle {
    z-index: 0;
    position: absolute;
    height: 150px;
    width: 205px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    transform: skewY(-15deg);
    border-radius: 5%;
    right: -10%;
    top: 15%;
}

.thanks-app-header-retangle::after {
    content: "";
    z-index: 0;
    position: absolute;
    height: 150px;
    width: 205px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    border-radius: 5%;
    left: -15%;
    bottom: -50%;
}

.thanks-app-header-retangle-2 {
    z-index: 0;
    position: absolute;
    height: 237px;
    width: 221px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    transform: skewY(-15deg);
    border-radius: 5%;
    left: 2%;
    bottom: -10%;
}

.thanks-app-header-retangle-2::after {
    content: "";
    position: absolute;
    height: 157px;
    width: 146.4px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    border-radius: 5%;
    top: -15%;
    right: -15%;
}

.thanks-app-header-content {
    padding: 28px 100px 12em 100px;
}

.thanks-app-header-content .row * {
    z-index: 0;
}

.thanks-app-header a,
.thanks-app-header a:hover,
.thanks-app-header a:focus {
    color: #fff;
    text-decoration: none;
}


.thanks-app-header-background {
    background: linear-gradient(
        71.68deg,
        #0e63f4 0%,
        #5b31ff 63.47%,
        #5a33ff 78.46%,
        #00e0fa 100%
    );
}

.thanks-app-header-retangle {
    height: 150px;
    width: 50vw;
    background: linear-gradient(
        5deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    border-radius: 0;
}

.thanks-app-header-retangle::after {
    left: unset;
    bottom: unset;
    top: 95%;
    right: 0;
    width: 90%;
    background: linear-gradient(
        5deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    border-radius: 0;
}

.thanks-app-header-retangle-2 {
    height: 65vh;
    width: 50vw;
    background: linear-gradient(
        5deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    border-radius: 0;
    left: -25%;
    bottom: -35%;
}

.thanks-app-header-retangle-2::after {
    height: 65vh;
    width: 50vw;
    background: linear-gradient(
        5deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    border-radius: 0;
    top: -15%;
    right: -15%;
}

.thanks-app-header h1 {
    max-width: 898px;
    display: block;
    margin: 0 auto;
}


@media (min-width: 1500px) {
    .thanks-app-header {
        min-height: 1097px !important;
    }
}


@media (min-width: 764px) {
    .title-page {
        padding-left: 5em;
        padding-right: 5em;
    }
}

@media (max-width: 764px){
  .thanks-app-header-content {
    padding: 1rem 0;
  }
  .thanks-app-header {
      overflow: hidden;
    }
}

@media (max-width: 1240px) {
    .navbar-expand-lg {
        flex-wrap: wrap !important;
        justify-content: space-between !important;
    }
}
